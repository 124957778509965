import { Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    PaymentAttemptState,
    paymentIsPending,
    usePaymentStatus,
} from '../hooks/Payments';
import { BlockingLoadingModal } from './BlockingLoadingModal';
import { FeedbackPopup } from './FeedbackPopup';
import { useNavigate } from 'react-router-dom';
import { useParkingaboAuthedPathGeneration } from '../routes/RouteUtils';
import { useEffect } from 'react';

export function PendingPaymentPoller({
    pendingPaymentId,
    onboarding,
    cancelling,
    twintPairingAborted,
    onPaymentCanceled,
    refetchUser,
}: {
    pendingPaymentId: string;
    onboarding?: boolean;
    cancelling?: boolean;
    twintPairingAborted?: boolean;
    onPaymentCanceled: () => void;
    refetchUser: () => void;
}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const { paymentStatusState, canCancel, paymentCancelState, cancelPayment } =
        usePaymentStatus(pendingPaymentId);

    useServerSuccessEffect(paymentCancelState, cancelStatus => {
        if (cancelStatus.success) {
            onPaymentCanceled();
        }
    });

    useServerSuccessEffect(paymentStatusState, status => {
        if (status.state === PaymentAttemptState.SETTLED) {
            refetchUser();
            navigate(
                generateAuthedParkingaboPath(
                    onboarding
                        ? 'onboarding/success'
                        : 'settings/payment/success',
                ),
            );
        } else if (status.state === PaymentAttemptState.ABORTED) {
            refetchUser();
            onPaymentCanceled();
        }
    });

    useEffect(() => {
        if (twintPairingAborted) {
            cancelPayment();
        }
    }, [twintPairingAborted]);

    return (
        <BlockingLoadingModal open={paymentIsPending(paymentStatusState.data)}>
            {cancelling ? (
                <FeedbackPopup
                    open={true}
                    color="warning"
                    title={
                        <Localized
                            de="Verarbeitung läuft..."
                            fr="Traitement en cours..."
                            it="Elaborazione in corso..."
                            en="Processing..."
                        />
                    }
                    abortLabel={
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    }
                    onAbort={canCancel ? () => cancelPayment() : undefined}
                >
                    <Localized
                        de="Die Transaktion wird abgebrochen."
                        fr="La transaction sera annulée."
                        it="La transazione viene annullata."
                        en="Transaction is being aborted."
                    />
                </FeedbackPopup>
            ) : (
                <FeedbackPopup
                    open={true}
                    color="warning"
                    title={
                        <Localized
                            de="Verarbeitung läuft..."
                            fr="Traitement en cours..."
                            it="Elaborazione in corso..."
                            en="Processing..."
                        />
                    }
                    abortLabel={
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    }
                    onAbort={canCancel ? () => cancelPayment() : undefined}
                >
                    <Localized
                        de="Bitte warten Sie, bis die Transaktion verarbeitet ist."
                        fr="Veuillez attendre que la transaction soit traitée."
                        it="Per favore attenda che la transazione venga elaborata."
                        en="Please wait until the transaction is processed."
                    />
                </FeedbackPopup>
            )}
            <FeedbackPopup
                open={
                    paymentCancelState.status === RequestStatus.ERROR ||
                    (paymentCancelState.status === RequestStatus.SUCCESS &&
                        !paymentCancelState.data.success)
                }
                color="error"
                title={
                    <Localized de="Fehler" fr="Erreur" it="Errore" en="Error" />
                }
            >
                <Typography style={{ marginBottom: theme.spacing(2) }}>
                    <Localized
                        de="Es ist ein Fehler beim Abbrechen aufgetreten. Bitte kontaktieren Sie den Support."
                        fr="Il y a eu une erreur avec la demande d'annulation, veuillez contacter le support."
                        it="C'è stato un errore con la richiesta di annullamento, la preghiamo di contattare il supporto."
                        en="There was an error with the cancel request, please contact support."
                    />
                </Typography>
                <Link href="">
                    <Localized
                        de="Erneut versuchen"
                        fr="Réessayer"
                        it="Riprova"
                        en="Retry"
                    />
                </Link>
            </FeedbackPopup>
            <FeedbackPopup
                open={
                    paymentStatusState.data?.state ===
                    PaymentAttemptState.ABORTED
                }
                color="error"
                onAbort={() =>
                    navigate(generateAuthedParkingaboPath('settings/user'))
                }
                abortLabel={
                    <Localized
                        de="Schliessen"
                        fr="Fermer"
                        it="Chiudi"
                        en="Close"
                    />
                }
                title={
                    <Localized
                        de="Zahlung abgebrochen"
                        fr="Paiement annulé"
                        it="Pagamento annullato"
                        en="Payment aborted"
                    />
                }
            >
                <Localized
                    de="Die Zahlung wurde abgebrochen. Überprüfen Sie Ihre Kreditkarte und versuchen Sie es erneut."
                    fr="Le paiement a été annulé. Vérifiez votre carte de crédit et réessayez."
                    it="Il pagamento è stato annullato. Controlli la sua carta di credito e riprovi."
                    en="The payment was aborted. Check your credit card and try again."
                />
            </FeedbackPopup>
        </BlockingLoadingModal>
    );
}
