import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { envIsProduction } from 'dg-web-shared/lib/Environment.ts';

const paramName = 'forceVisibility';

export function useForceProductVisibility() {
    const [searchParams, setSearchParams] = useSearchParams();
    const forceVisibilityParam = searchParams.get(paramName);
    const [forceVisibility, setForceVisibility] = useState(
        !envIsProduction() && Boolean(forceVisibilityParam),
    );
    useEffect(() => {
        if (!envIsProduction()) {
            if (!forceVisibility && forceVisibilityParam === 'true') {
                setForceVisibility(true);
            }
            if (forceVisibility && forceVisibilityParam === 'false') {
                setForceVisibility(false);
                const newParams = new URLSearchParams(searchParams);
                newParams.delete(paramName);
                setSearchParams(newParams);
            }
            if (
                forceVisibility &&
                forceVisibilityParam !== 'true' &&
                forceVisibilityParam !== 'false'
            ) {
                const newParams = new URLSearchParams(searchParams);
                newParams.set(paramName, 'true');
                setSearchParams(newParams);
            }
        }
    }, [forceVisibilityParam, forceVisibility]);
    return forceVisibility;
}
