import { useNavigate } from 'react-router-dom';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi.tsx';
import { FeedbackPopup } from '../../components/FeedbackPopup.tsx';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';

export enum ValidationState {
    LOADING = 'LOADING',
    REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE = 'REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE',
    REDIRECT_TO_LOGIN_ONBOARDING_PENDING = 'REDIRECT_TO_LOGIN_ONBOARDING_PENDING',
    CONFIRM_EMAIL = 'CONFIRM_EMAIL',
    NOT_FOUND = 'NOT_FOUND',
    WRONG_CUSTOMER = 'WRONG_CUSTOMER',
    GENERIC_ERROR = 'GENERIC_ERROR',
}

export enum ValidationOrigin {
    CUSTOMER = 'CUSTOMER',
    BADGE = 'BADGE',
}

export interface PublicValidationResponse {
    isOnboardingComplete: boolean;
    hasLogin: boolean;
    emailToBeConfirmed: string | null;
}

export enum ValidationErrorReason {
    NOT_FOUND = 'NOT_FOUND',
    WRONG_CUSTOMER = 'WRONG_CUSTOMER',
}

export function useResentVerificationMail() {
    const [anotherLinkState, requestAnotherLink] = useParkingaboServerWrite<
        { email: string },
        never
    >(() => ({
        url: '/ui-api/parkingabo/resend-verification-link',
    }));
    return { anotherLinkState, requestAnotherLink };
}

export function PublicValidationContent({
    validationState,
    emailToBeConfirmed,
    requestAnotherLink,
    removeRegistration,
    origin,
}: {
    validationState: ValidationState;
    emailToBeConfirmed: string | null;
    requestAnotherLink: () => void;
    removeRegistration: () => void;
    origin: ValidationOrigin;
}) {
    const navigate = useNavigate();
    switch (validationState) {
        case ValidationState.LOADING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE:
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING:
            return (
                <RedirectToLogin
                    onConfirm={() => navigate('/login')}
                    validationState={validationState}
                    origin={origin}
                />
            );
        case ValidationState.CONFIRM_EMAIL:
            return (
                <ConfirmEmailPopup
                    emailToBeConfirmed={emailToBeConfirmed!}
                    onConfirm={() => requestAnotherLink()}
                    onAbort={() => removeRegistration()}
                />
            );
        case ValidationState.NOT_FOUND:
            return (
                <NotFoundPopup
                    onConfirm={() => navigate('/login')}
                    origin={origin}
                />
            );
        case ValidationState.GENERIC_ERROR:
            return <GenericErrorPopup onConfirm={() => navigate('/login')} />;
        default:
            return null;
    }
}

export function AuthedValidationContent({
    validationState,
    origin,
}: {
    validationState: ValidationState;
    origin: ValidationOrigin;
}) {
    const navigate = useNavigate();

    switch (validationState) {
        case ValidationState.LOADING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case ValidationState.WRONG_CUSTOMER:
            return (
                <ErrorPopupByValidationErrorReason
                    onConfirm={() => navigate('/login')}
                    origin={origin}
                />
            );
        case ValidationState.NOT_FOUND:
            return (
                <NotFoundPopup
                    onConfirm={() => navigate('/login')}
                    origin={origin}
                />
            );
        case ValidationState.GENERIC_ERROR:
            return <GenericErrorPopup onConfirm={() => navigate('/login')} />;
        default:
            return null;
    }
}

function RedirectToLogin({
    onConfirm,
    validationState,
    origin,
}: {
    onConfirm: () => void;
    validationState:
        | ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE
        | ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING;
    origin: ValidationOrigin;
}) {
    switch (validationState) {
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE:
            return (
                <FeedbackPopup
                    open={true}
                    color="success"
                    onConfirm={onConfirm}
                    abortLabel={'OK'}
                    title={
                        <Localized
                            de={`Gültiges ${origin === ValidationOrigin.BADGE ? 'diesen Badge' : 'dieses Konto'}`}
                            fr={`${origin === ValidationOrigin.BADGE ? 'Badge' : 'Compte'} valable`}
                            it={`${origin === ValidationOrigin.BADGE ? 'Badge' : 'Conto'} valido`}
                            en={`Valid ${origin === ValidationOrigin.BADGE ? 'badge' : 'account'}`}
                        />
                    }
                >
                    <p>
                        <Localized
                            de={`${origin === ValidationOrigin.BADGE ? 'Dieser Badge' : 'Dieses Konto'} ist im Parkingabo-System korrekt konfiguriert.`}
                            fr={`Ce ${origin === ValidationOrigin.BADGE ? 'badge' : 'compte'} est correctement configuré dans le système Parkingabo.`}
                            it={`Questo ${origin === ValidationOrigin.BADGE ? 'badge' : 'conto'} è configurato correttamente nel sistema Parkingabo.`}
                            en={`This ${origin === ValidationOrigin.BADGE ? 'badge' : 'account'} is correctly configured in the Parkingabo system.`}
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wenn Sie Ihre Konfiguration überprüfen/ändern oder Ihre Transaktionen einsehen möchten, melden Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto an."
                            fr="Si vous souhaitez vérifier/modifier votre configuration ou consulter vos transactions, connectez-vous à votre compte Parkingabo avec vos identifiants."
                            it="Se vuole verificare/modificare la configurazione o consultare le transazioni, acceda al tuo conto Parkingabo con le sue credenziali."
                            en="If you want to check/change your configuration or consult your transactions, log in to your Parkingabo account with your credentials."
                        />
                    </p>
                </FeedbackPopup>
            );
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING:
            return (
                <FeedbackPopup
                    open={true}
                    color="warning"
                    onConfirm={onConfirm}
                    abortLabel={'OK'}
                    title={
                        <Localized
                            de="Konfiguration nicht vollständig"
                            fr="Configuration incomplet"
                            it="Configurazione non completa"
                            en="Configuration not complete"
                        />
                    }
                >
                    <p>
                        <Localized
                            de={`Um ${origin === ValidationOrigin.BADGE ? 'diesen Badge' : 'dieses Konto'} nutzen zu können, müssen Sie die Konfiguration abschließen, indem Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto anmelden und den Anweisungen folgen.`}
                            fr={`Pour utiliser ce ${origin === ValidationOrigin.BADGE ? 'badge' : 'compte'}, vous devez compléter votre configuration en vous connectant à votre compte Parkingabo avec vos identifiants et en suivant les instructions.`}
                            it={`Per poter utilizzare questo ${origin === ValidationOrigin.BADGE ? 'badge' : 'conto'}, deve completare la configurazione accedendo al suo conto con le sue credenziali e seguendo le istruzioni.`}
                            en={`In order to use this ${origin === ValidationOrigin.BADGE ? 'badge' : 'account'}, you must complete the configuration by logging into your Parkingabo account using your credentials and following the instructions.`}
                        />
                    </p>
                </FeedbackPopup>
            );
    }
}

function ConfirmEmailPopup({
    emailToBeConfirmed,
    onConfirm,
    onAbort,
}: {
    emailToBeConfirmed: string;
    onConfirm: () => void;
    onAbort: () => void;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="ERNEUT SENDEN"
                    fr="RENVOYER"
                    it="INVIA DI NUOVO"
                    en="RESEND"
                />
            }
            onAbort={onAbort}
            abortLabel={
                <Localized
                    de="ERNEUT REGISTRIEREN"
                    fr="RÉPÉTER L'INSCRIPTION"
                    it="RIPETI ISCRIZIONE"
                    en="REPEAT REGISTRATION"
                />
            }
            title={
                <Localized
                    de="Unbestätigte E-Mail Adresse"
                    fr="Adresse e-mail non confirmée"
                    it="Indirizzo e-mail non confermato"
                    en="Unconfirmed e-mail address"
                />
            }
        >
            <Localized
                de={
                    <p>
                        Sie haben die E-Mail-Adresse <b>{emailToBeConfirmed}</b>{' '}
                        noch nicht bestätigt.
                    </p>
                }
                fr={
                    <p>
                        Vous n&#39;avez pas encore confirmé l&#39;adresse e-mail{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
                it={
                    <p>
                        Non ha ancora confermato l&#39;indirizzo e-mail{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
                en={
                    <p>
                        You have not yet confirmed the email address{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
            />
            <p>
                <Localized
                    de="Sollten Sie die E-Mail-Mitteilung mit dem Bestätigungslink nicht erhalten haben, überprüfen Sie in Ihrem E-Mail-Programm den Spam-Ordner."
                    fr="Veuillez vérifier le dossier spam dans votre programme de messagerie si vous n'avez pas reçu le message e-mail avec le lien de confirmation."
                    it="Se non ha ricevuto il messagio e-mail con il link di conferma, verifichi per favore la cartella spam (posta indesiderata) nel suo programma di posta elettronica."
                    en="Please also check the spam folder in your email program, if you have not received the email message with the confirmation link."
                />
            </p>
            <p>
                <Localized
                    de="Sie können mit «ERNEUT SENDEN» einen neuen Bestätigungslink anfordern oder mit «ERNEUT REGISTRIEREN» die E-Mail-Adresse korrigieren."
                    fr="Vous pouvez demander un nouveau lien de confirmation avec «RENVOYER» ou corriger votre adresse e-mail avec «RÉPÉTER L'INSCRIPTION»."
                    it="Può richiedere un nuovo link di conferma cliccando su «INVIA DI NUOVO» oppure può correggere l'indirizzo e-mail con «RIPETI ISCRIZIONE»."
                    en="You can request a new confirmation link with «RESEND» or you can correct your e-mail address with «REPEAT REGISTRATION»."
                />
            </p>
        </FeedbackPopup>
    );
}

function ErrorPopupByValidationErrorReason({
    onConfirm,
    origin,
}: {
    onConfirm: () => void;
    origin: ValidationOrigin;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="Bereits registriert"
                    fr="Déjà enregistré"
                    it="Già registrato"
                    en="Already registered"
                />
            }
        >
            <p>
                <Localized
                    de={`Sie sind bereits bei Parkingabo registriert und können sich daher nicht erneut mit diesem ${origin === ValidationOrigin.BADGE ? 'Badge' : 'Link'} registrieren.`}
                    fr={`Vous êtes déjà enregistré dans Parkingabo et ne pouvez donc pas vous enregistrer à nouveau avec ce ${origin === ValidationOrigin.BADGE ? 'badge' : 'lien'}.`}
                    it={`Siete già registrato in Parkingabo e quindi non è possibile effettuare una nuova registrazione con questo ${origin === ValidationOrigin.BADGE ? 'badge' : 'collegamento'}.`}
                    en={`You are already registered in Parkingabo and therefore cannot register again with this ${origin === ValidationOrigin.BADGE ? 'badge' : 'link'}.`}
                />
            </p>
        </FeedbackPopup>
    );
}

function NotFoundPopup({
    onConfirm,
    origin,
}: {
    onConfirm: () => void;
    origin: ValidationOrigin;
}) {
    switch (origin) {
        case ValidationOrigin.BADGE:
            return (
                <FeedbackPopup
                    open={true}
                    color="error"
                    onConfirm={onConfirm}
                    confirmLabel={'OK'}
                    title={
                        <Localized
                            de="Badge nicht gefunden"
                            fr="Badge introuvable"
                            it="Badge non trovato"
                            en="Badge not found"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Dieser Badge ist entweder im Parkingabo-System nicht konfiguriert oder kann nicht Online abgefragt werden."
                            fr="Ce badge n'est pas configuré dans le système Parkingabo ou ne peut pas être consulté en ligne."
                            it="Questo badge non è configurato nel sistema Parkingabo oppure non può essere consultato online."
                            en="This badge is either not configured in the Parkingabo system or cannot be accessed online."
                        />
                    </p>
                </FeedbackPopup>
            );
        case ValidationOrigin.CUSTOMER:
            return (
                <FeedbackPopup
                    open={true}
                    color="error"
                    onConfirm={onConfirm}
                    confirmLabel={'OK'}
                    title={
                        <Localized
                            de="Konto nicht gefunden"
                            fr="Compte introuvable"
                            it="Conto non trovato"
                            en="Account not found"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Dieses Konto ist entweder im Parkingabo-System nicht konfiguriert oder kann nicht Online abgefragt werden."
                            fr="Ce compte n'est pas configuré dans le système Parkingabo ou ne peut pas être consulté en ligne."
                            it="Questo conto non è configurato nel sistema Parkingabo oppure non può essere consultato online."
                            en="This badge is either not configured in the Parkingabo system or cannot be accessed online."
                        />
                    </p>
                </FeedbackPopup>
            );
    }
}

export function GenericErrorPopup({ onConfirm }: { onConfirm: () => void }) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            }
        >
            <p>
                <Localized
                    de="Bitte versuchen Sie es in ein paar Minuten erneut."
                    fr="Veuillez réessayer dans quelques minutes."
                    it="Voglia riprovare tra qualche minuto."
                    en="Please try again in a few minutes."
                />
            </p>
        </FeedbackPopup>
    );
}
