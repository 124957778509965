import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';

export function OnboardingSuccessRoute() {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <FeedbackPopup
            open={true}
            color="success"
            onAbort={() => {
                navigate(generateAuthedParkingaboPath(''));
            }}
            abortLabel={'OK'}
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
        >
            <p>
                <Localized
                    de="Die Konfiguration wurde erfolgreich abgeschlossen."
                    fr="La configuration a été complétée avec succès."
                    it="La configurazione è stata conclusa con successo."
                    en="The Setup was successfully completed."
                />
            </p>
            <p>
                <Localized
                    de="Die eingegebenen Informationen können jederzeit in Ihrem Parkingabo-Konto geändert werden."
                    fr="Les informations saisies peuvent être modifiées à tout moment dans votre compte Parkingabo."
                    it="Le informazioni inserite possono essere modificate in qualsiasi momento nel suo conto Parkingabo."
                    en="The information entered can be changed at any time in your Parkingabo account."
                />
            </p>
        </FeedbackPopup>
    );
}
