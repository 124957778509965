import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    VehicleForm,
    VehicleOnboardingPayload,
} from '../../../components/forms/VehicleForm';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import {
    makeCountryOptions,
    useCountryFetch,
} from '../../../hooks/UseCountryFetch';
import { AuthedRouteCompProps } from '../../RouteUtils';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { useNavigate } from 'react-router-dom';

export function VehiclesDetailRoute({ vehicles }: AuthedRouteCompProps) {
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const navigate = useNavigate();

    const vehicle = useMemo(() => {
        return vehicles.data.find(v => v.customerTenantCarId === vehicleId);
    }, [vehicleId]);

    const [countriesState] = useCountryFetch();
    const [refetchLoading, setRefetchLoading] = useState(false);

    const [state, submit] = useParkingaboServerWrite<
        VehicleOnboardingPayload,
        never
    >(() => ({
        url: `/ui-api/parkingabo/user/self/vehicles/${vehicleId}`,
        method: RequestMethod.PUT,
    }));

    const [vehicleDeleteState, deleteVehicle] = useParkingaboServerWrite<
        { vehicleId: string },
        never
    >(context => ({
        url: `/ui-api/parkingabo/user/self/vehicles/${context.vehicleId}`,
        method: RequestMethod.DELETE,
    }));

    useServerSuccessEffect(state, () => {
        vehicles.refetch();
        setRefetchLoading(true);
    });

    useEffect(() => {
        if (refetchLoading && vehicles.status === RequestStatus.SUCCESS) {
            navigate('..');
        }
    }, [vehicles.status]);

    useServerSuccessEffect(vehicleDeleteState, () => {
        vehicles.refetch();
        navigate('..', { replace: true });
    });

    useEffect(() => {
        if (!vehicle) {
            navigate('..', { replace: true });
        }
    }, [vehicle]);

    function handleVehicleDelete() {
        if (vehicleId) {
            deleteVehicle({ vehicleId });
        }
    }

    if (!vehicle) {
        return null;
    }

    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl={'..'}
            render={() => (
                <ParkingaboLayoutWithHeader
                    backTo={'..'}
                    title={vehicle.description}
                >
                    <ParkingaboAsyncLoadedSection
                        state={countriesState}
                        render={data => {
                            return (
                                <>
                                    <VehicleForm
                                        countriesOptions={makeCountryOptions(
                                            data,
                                        )}
                                        requestState={state}
                                        submit={submit}
                                        initialValues={vehicle}
                                        deleteVehicle={
                                            vehicles.data.length > 1
                                                ? handleVehicleDelete
                                                : undefined
                                        }
                                        vehicleDeleteState={vehicleDeleteState}
                                        loading={refetchLoading}
                                    />
                                </>
                            );
                        }}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}
