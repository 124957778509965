import { Box, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { ParkingaboLink } from './ParkinaboLink';
import { rgba } from 'utils/src/Rgba';
import { To } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

export function ParkingaboMenuListItem({
    icons,
    text,
    supText,
    onClick,
    hideChevron,
    to,
}: {
    icons?: ReactNode[];
    text?: JSX.Element | ReactNode;
    supText?: JSX.Element | string;
    onClick?: (e?: MouseEvent) => void;
    hideChevron?: boolean;
    to?: string;
}) {
    return (
        <ParkingaboListItem to={to} onClick={onClick}>
            <Box>
                {supText && (
                    <Typography variant="overline">{supText}</Typography>
                )}
                {text && (
                    <Typography
                        fontWeight="bold"
                        sx={{
                            textTransform: supText ? undefined : 'uppercase',
                        }}
                    >
                        {text}
                    </Typography>
                )}
            </Box>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {icons}
                {!hideChevron && (to || onClick) && <ParkingaboListChevron />}
            </Box>
        </ParkingaboListItem>
    );
}

export function ParkingaboListChevron() {
    return <ChevronRight sx={{ fontSize: 36 }} />;
}

function getBackgroundColorForVariant(
    theme: Theme,
    colorVariant: MenuListItemColorVariant,
) {
    switch (colorVariant) {
        case MenuListItemColorVariant.PRIMARY:
            return theme.palette.primary.light;
        case MenuListItemColorVariant.SUCCESS:
            return rgba(theme.palette.success.light, 0.2);
        case MenuListItemColorVariant.WARNING:
            return rgba(theme.palette.warning.light, 0.2);
        case MenuListItemColorVariant.DISABLED:
            return theme.palette.grey.A200;
        case MenuListItemColorVariant.WHITE:
            return undefined;
    }
}

function getBackgroundColorForVariantOnHover(
    theme: Theme,
    colorVariant: MenuListItemColorVariant,
) {
    switch (colorVariant) {
        case MenuListItemColorVariant.PRIMARY:
            return rgba(theme.palette.primary.main, 0.25);
        case MenuListItemColorVariant.SUCCESS:
            return rgba(theme.palette.success.light, 0.4);
        case MenuListItemColorVariant.WARNING:
            return rgba(theme.palette.warning.light, 0.4);
        case MenuListItemColorVariant.DISABLED:
            return rgba(theme.palette.grey['600'], 0.25);
        case MenuListItemColorVariant.WHITE:
            return rgba(theme.palette.primary.main, 0.05);
    }
}

export enum MenuListItemColorVariant {
    WHITE = 'WHITE',
    PRIMARY = 'PRIMARY',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    DISABLED = 'DISABLED',
}

export function ParkingaboListItem({
    to,
    onClick,
    children,
    variableHeight,
    colorVariant = MenuListItemColorVariant.WHITE,
    showBottomBorder = true,
}: {
    to?: To;
    onClick?: () => void;
    children: ReactNode;
    variableHeight?: boolean;
    colorVariant?: MenuListItemColorVariant;
    showBottomBorder?: boolean;
}) {
    const clickable = to || onClick;
    const borderStyle = (theme: Theme) => {
        switch (colorVariant) {
            case MenuListItemColorVariant.WHITE:
            case MenuListItemColorVariant.PRIMARY:
                return `solid 1px ${theme.palette.grey[300]}`;
            case MenuListItemColorVariant.SUCCESS:
                return `solid 1px ${theme.palette.success.main}`;
            case MenuListItemColorVariant.WARNING:
                return `solid 1px ${theme.palette.warning.main}`;
            case MenuListItemColorVariant.DISABLED:
                return `solid 1px ${theme.palette.grey['600']}`;
        }
    };
    return (
        <ParkingaboLink
            to={to ?? ''}
            sx={theme => ({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
                height: variableHeight ? undefined : 80,
                minHeight: 80,
                cursor: clickable ? 'pointer' : 'default',
                backgroundColor: getBackgroundColorForVariant(
                    theme,
                    colorVariant,
                ),
                '&:hover': clickable
                    ? {
                          backgroundColor: getBackgroundColorForVariantOnHover(
                              theme,
                              colorVariant,
                          ),
                      }
                    : undefined,
                '& + a': {
                    borderTop: borderStyle,
                },
                '&:last-child': {
                    borderBottom: showBottomBorder ? borderStyle : undefined,
                },
            })}
            underline="none"
            onClick={onClick}
        >
            {children}
        </ParkingaboLink>
    );
}
