import { Theme, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    lowerCasePasswordRegex,
    numericPasswordRegex,
    symbolsPasswordRegex,
    upperCasePasswordRegex,
} from 'dg-web-shared/lib/auth/PasswordRegexp';
import { FC } from 'react';

function colorByStrength(theme: Theme, strength: number) {
    switch (strength) {
        case 0:
            return theme.palette.grey[400];
        case 1:
        case 2:
        case 3:
            return theme.palette.grey[800];
        case 4:
            return theme.palette.error.main;
        case 5:
            return theme.palette.warning.main;
        case 6:
            return theme.palette.success.light;
        case 7:
        default:
            return theme.palette.success.main;
    }
}

interface PasswordStrengthIndicatorProps {
    passwordStrength: number;
}

export const PasswordStrengthIndicator: FC<PasswordStrengthIndicatorProps> = ({
    passwordStrength,
}) => {
    const theme = useTheme();

    return (
        <Box
            style={{
                display: 'flex',
                marginTop: theme.spacing(2),
            }}
        >
            <Typography color="primary" style={{ fontWeight: 800 }}>
                <Localized
                    de="Sicherheit:"
                    fr="Sécurité:"
                    it="Sicurezza:"
                    en="Security:"
                />
            </Typography>
            {passwordStrength > 0 && (
                <>
                    <div
                        style={{
                            borderRadius: '50%',
                            width: 24,
                            height: 24,
                            backgroundColor: colorByStrength(
                                theme,
                                passwordStrength,
                            ),
                            marginRight: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                        }}
                    />
                    <Typography
                        style={{
                            color: colorByStrength(theme, passwordStrength),
                            fontWeight: 800,
                        }}
                    >
                        <PasswordStrengthText
                            passwordStrength={passwordStrength}
                        />
                    </Typography>
                </>
            )}
            {passwordStrength === 0 && (
                <Typography style={{ marginLeft: theme.spacing(1) }}>
                    {' '}
                    -{' '}
                </Typography>
            )}
        </Box>
    );
};

const PasswordStrengthText: FC<{ passwordStrength: number }> = ({
    passwordStrength,
}) => {
    if (passwordStrength < 4) {
        return (
            <Localized
                de="sehr schwach"
                fr="trop faible"
                it="troppo debole"
                en="too weak"
            />
        );
    }

    switch (passwordStrength) {
        case 4:
            return <Localized de="schwach" fr="faible" it="debole" en="weak" />;
        case 5:
            return (
                <Localized de="mittel" fr="moyenne" it="media" en="medium" />
            );
        case 6:
            return <Localized de="stark" fr="forte" it="forte" en="strong" />;
        case 7:
        default:
            return (
                <Localized
                    de="sehr stark"
                    fr="très forte"
                    it="molto forte"
                    en="very strong"
                />
            );
    }
};

export function computePasswordStrength(password: string | null) {
    if (!password || password.length === 0) {
        return { valid: false, strength: 0 };
    }

    if (password.length < 6) {
        return { valid: false, strength: 1 };
    }

    let points = 0;

    points += +new RegExp(lowerCasePasswordRegex).test(password);
    points += +new RegExp(upperCasePasswordRegex).test(password);
    points += +new RegExp(symbolsPasswordRegex).test(password);
    points += +new RegExp(numericPasswordRegex).test(password);
    points += +(password.length >= 6);
    if (points < 3) {
        return { valid: false, strength: points };
    }

    points += +(password.length >= 8);
    points += +(password.length >= 12);

    return { valid: points >= 4, strength: points };
}
