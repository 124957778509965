import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboHeader } from '../../../components/layout/ParkingaboHeader';
import { ParkingaboLayout } from '../../../components/layout/ParkingaboLayout';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import {
    OnboardingStepType,
    useNavigateOnStepCompletion,
    useOnboarding,
    useSetOnboardingStepAsCompleteOnSuccess,
} from './OnboardingConfig';
import { useUserUpdate } from '../settings/EditProfileRoute';
import { UserDataForm } from '../../../components/forms/UserDataForm';
import { useLogin } from '../../AppRoutes';

export function OnboardingUserDataRoute({ refetchUser }: AuthedRouteCompProps) {
    const login = useLogin();
    const theme = useTheme();
    const { config } = useOnboarding();
    const currentStep = config.steps.findIndex(
        c => c.type === OnboardingStepType.USER_DATA,
    );
    const totalSteps = config.steps.length;
    const generatePublicParkingaboPath = useParkingaboAuthedPathGeneration();

    const { state, submit } = useUserUpdate(refetchUser);

    useNavigateOnStepCompletion(currentStep, generatePublicParkingaboPath(''));

    useSetOnboardingStepAsCompleteOnSuccess(state, currentStep);

    return (
        <ParkingaboLayout>
            <ParkingaboHeader />
            <Typography
                variant="h1"
                style={{
                    marginTop: theme.spacing(3),
                }}
            >
                <Localized
                    de="Benutzer"
                    fr="Utilisateur"
                    it="Utente"
                    en="User"
                />
            </Typography>
            <Typography
                variant="h3"
                style={{
                    marginBottom: theme.spacing(5),
                    marginTop: theme.spacing(2),
                }}
            >
                <Localized
                    de={`Konfiguration (${currentStep + 1} von ${totalSteps})`}
                    fr={`Configuration (${currentStep + 1} de ${totalSteps})`}
                    it={`Configurazione (${currentStep + 1} di ${totalSteps})`}
                    en={`Setup (${currentStep + 1} of ${totalSteps})`}
                />
            </Typography>
            <UserDataForm.UserBaseForm
                requestState={state}
                submit={submit}
                render={control => (
                    <>
                        <UserDataForm.NameField control={control} />
                        <UserDataForm.LanguageFieldWithRadios
                            control={control}
                        />
                    </>
                )}
                onCancel={login.logout}
            />
        </ParkingaboLayout>
    );
}
