import { ComponentType, ReactNode, useEffect, useState } from 'react';
import { Box, Card, Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { WizardFadeInEffect } from './WizardComponents';
import {
    DateCalendar,
    DateView,
    PickersDay,
    PickersDayProps,
} from '@mui/x-date-pickers';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { Formatter } from 'dg-web-shared/lib/Date';
import { ParkingaboTimePicker, TimeSelection } from './ParkingaboTimeSelector';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import rgba = Colors.rgba;

export const enum AvailabilityLevel {
    FILLING = 'FILLING',
    FULL = 'FULL',
}

export const enum WeekDay {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 7,
}

export function TimeSelectionStep({
    date,
    time,
    onTimeChange,
}: {
    date: DateTime;
    time: TimeSelection;
    onTimeChange: (value: TimeSelection, userInteraction: boolean) => void;
}) {
    return (
        <WizardFadeInEffect>
            <ParkingaboTimePicker
                date={date}
                time={time}
                onTimeChange={onTimeChange}
                disablePast
            />
        </WizardFadeInEffect>
    );
}

export function MultiDateSelectionStep({
    dates,
    onChange,
    minValidityDate,
    maxSelectableDates,
    maxDaysInFuture,
    disabledWeekdays,
    availability,
}: {
    dates: DateTime[];
    minValidityDate: DateTime | null;
    onChange: (dates: DateTime[]) => void;
    maxSelectableDates: number;
    maxDaysInFuture: number;
    disabledWeekdays?: WeekDay[];
    availability?: Record<string, AvailabilityLevel>;
}) {
    const now = DateTime.now().startOf('day');
    const minDate =
        minValidityDate !== null && now.toMillis() < minValidityDate.toMillis()
            ? minValidityDate
            : now;
    const [lastDate, setLatestDate] = useState(dates[0] ?? minDate);
    const maxSelected =
        maxSelectableDates <= dates.length && maxSelectableDates !== 1;
    const [view, setView] = useState<DateView>('day');

    useEffect(() => {
        // for some reason calendar picker triggers an on change event for now, which we need to reset with minDate
        setLatestDate(dates[0] ?? minDate);
    }, []);

    const availabilityInfo: { [id: string]: AvailabilityLevel } =
        availability || {};

    const hasWarningDays =
        Object.values(availabilityInfo).filter(
            availabilityLevel => availabilityLevel == AvailabilityLevel.FILLING,
        ).length > 0;
    const hasFullyBookedDays =
        Object.values(availabilityInfo).filter(
            availabilityLevel => availabilityLevel == AvailabilityLevel.FULL,
        ).length > 0;

    const dayProperties: CalendarInfoProps = {
        dates: dates,
        maxSelected: maxSelected,
        availabilityInfo: availabilityInfo,
        disabledWeekdays: disabledWeekdays ?? [],
    };

    return (
        <WizardFadeInEffect>
            <PickerHeader>
                <MultiDatePickerInfoText
                    dates={dates}
                    maxSelectableDates={maxSelectableDates}
                />
            </PickerHeader>

            <PickerContainer>
                <DateCalendar
                    value={lastDate}
                    onChange={date => {
                        if (date) {
                            const dateTime = date.startOf('day');
                            setLatestDate(dateTime);
                            const index = findIndexOfDate(dates, dateTime);
                            switch (view) {
                                case 'day':
                                    if (index === -1) {
                                        if (maxSelectableDates === 1) {
                                            onChange([dateTime]);
                                        } else {
                                            onChange([...dates, dateTime]);
                                        }
                                    } else {
                                        onChange(
                                            dates.filter(
                                                item => !item.equals(dateTime),
                                            ),
                                        );
                                    }
                                    break;
                                case 'month':
                                    setView('day');
                                    break;
                                case 'year':
                                    setView('month');
                                    break;
                            }
                        }
                    }}
                    view={view}
                    onViewChange={setView}
                    disablePast
                    minDate={minDate}
                    maxDate={now.plus({ days: maxDaysInFuture })}
                    slots={{
                        day: DaySelection as ComponentType<
                            PickersDayProps<DateTime>
                        >,
                    }}
                    slotProps={{
                        day: dayProperties as DaySelectionProps,
                    }}
                />
            </PickerContainer>

            {hasWarningDays && <WarningFewSpacesLegend />}

            {hasFullyBookedDays && <NoMoreSpacesLegend />}
        </WizardFadeInEffect>
    );
}

type DaySelectionProps = PickersDayProps<DateTime> & CalendarInfoProps;
interface CalendarInfoProps {
    dates: DateTime[];
    maxSelected: boolean;
    availabilityInfo: { [p: string]: AvailabilityLevel };
    disabledWeekdays: WeekDay[];
}

function DaySelection(props: DaySelectionProps) {
    const selected = findDate(props.dates, props.day);
    const iso = props.day.toISODate();
    const styling = function (theme: Theme) {
        const style = {
            '&&:focus': theme.palette.background.default,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
                color: theme.palette.text.primary,
            },
        };
        if (selected) style['&&:focus'] = theme.palette.primary.main;
        if (props.availabilityInfo[iso] == AvailabilityLevel.FILLING) {
            style['backgroundColor'] = theme.palette.warning.main;
            style['color'] = theme.palette.warning.contrastText;
            style['&:hover'] = {
                backgroundColor: rgba(theme.palette.warning.light, 0.2),
                color: theme.palette.warning.main,
            };
        }
        if (props.availabilityInfo[iso] == AvailabilityLevel.FULL) {
            style['backgroundColor'] = theme.palette.error.main;
            style['color'] = `${theme.palette.error.contrastText} !important`;
            style['&:hover'] = {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.disabled,
            };
        }
        return style;
    };

    return (
        <PickersDay
            {...props}
            selected={selected}
            disabled={
                props.disabled ||
                (props.maxSelected && !selected) ||
                props.disabledWeekdays?.includes(props.day.weekday) ||
                props.availabilityInfo[props.day.toISODate()] ==
                    AvailabilityLevel.FULL
            }
            sx={styling}
        />
    );
}

function PickerHeader({ children }: { children: ReactNode }) {
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
            })}
        >
            <Typography fontWeight="medium">{children}</Typography>
        </Box>
    );
}

function WarningFewSpacesLegend() {
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.spacing(2),
                color: theme.palette.warning.main,
            })}
        >
            <Typography fontWeight="medium">
                <Localized
                    de="Über 80% reserviert"
                    fr="Plus de 80% réservés"
                    it="Più dell'80% riservato"
                    en="Over 80% reserved"
                />
            </Typography>
        </Box>
    );
}

function NoMoreSpacesLegend() {
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.spacing(2),
                color: theme.palette.error.main,
            })}
        >
            <Typography fontWeight="medium">
                <Localized
                    de="Keine freien Plätze verfügbar"
                    fr="Pas d'espaces libres disponibles"
                    it="Non ci sono spazi liberi disponibili"
                    en="No free spaces available"
                />
            </Typography>
        </Box>
    );
}

function PickerContainer({ children }: { children: React.ReactNode }) {
    return (
        <Card
            elevation={0}
            sx={theme => ({
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                minHeight: '358px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            })}
        >
            {children}
        </Card>
    );
}

function MultiDatePickerInfoText({
    dates,
    maxSelectableDates,
}: {
    dates: DateTime[];
    maxSelectableDates: number;
}) {
    switch (dates.length) {
        case 0:
            return maxSelectableDates > 1 ? (
                <Localized
                    de="Wählen Sie die gewünschten Tage"
                    fr="Sélectionnez les jours souhaités"
                    it="Selezionare i gironi desiderati"
                    en="Select the desired days"
                />
            ) : (
                <Localized
                    de="Wählen Sie das gewünschte Datum"
                    fr="Sélectionner une date"
                    it="Selezionare la data desiderata"
                    en="Select the desired date"
                />
            );
        case 1:
            return <>{Formatter.dayMonthYear(dates[0])}</>;
        default:
            return (
                <>
                    {dates.length}{' '}
                    <Localized de="Tage" fr="jours" it="giorni" en="days" />
                </>
            );
    }
}

function findDate(dates: DateTime[], date: DateTime): boolean {
    return dates.some(item => item.equals(date));
}

function findIndexOfDate(dates: DateTime[], date: DateTime): number {
    return dates.findIndex(item => item.equals(date));
}
