import { Box, Stack, Typography } from '@mui/material';
import {
    LandscapeOverlayIcon,
    OrientationConditional,
    WindowOrientation,
} from 'dg-web-shared/common/components/LandscapeOverlayDisplay';
import { ParkingaboDisplayPriority } from './ParkingaboDisplayPriority';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { isMobile } from 'dg-web-shared/common/utils/BrowserOrigin';

export const MAX_HEIGHT_DESKTOP = '812px';
export const MAX_WIDTH_DESKTOP = 420;

export function MainLayout({ children }: { children: React.ReactNode }) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                overflow: 'hidden',
                maxHeight: !isMobile() ? MAX_HEIGHT_DESKTOP : undefined,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: !isMobile() ? MAX_WIDTH_DESKTOP : undefined,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    height: '100%',
                    maxHeight:
                        'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
                }}
            >
                {children}
            </Box>
            <OrientationConditional
                visibleOrientation={WindowOrientation.landscape}
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={6}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: ParkingaboDisplayPriority.landscapeOverlay,
                        backgroundColor: theme => {
                            console.log(theme);
                            return theme.palette.background.default;
                        },
                    }}
                >
                    <LandscapeOverlayIcon />
                    <Typography
                        variant="h3"
                        sx={{
                            maxWidth: '170px',
                        }}
                    >
                        <Localized
                            de="Bitte wechseln Sie zurück zum Hochformatmodus."
                            fr="Veuillez revenir en mode portrait."
                            it="Voglia tornare alla modalità verticale."
                            en="Please rotate back to portrait mode."
                        />
                    </Typography>
                </Stack>
            </OrientationConditional>
        </Box>
    );
}
