import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../api/ParkingaboApi.tsx';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    useNavigateOnError,
    useNavigateOnSuccess,
    useServerErrorEffect,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useState } from 'react';
import { useParkingaboAuthedPathGeneration } from '../RouteUtils.tsx';
import {
    AuthedValidationContent,
    PublicValidationContent,
    PublicValidationResponse,
    useResentVerificationMail,
    ValidationErrorReason,
    ValidationOrigin,
    ValidationState,
} from './ParkingaboSharedRegistrationValidationRoute.tsx';

export function usePublicCustomerValidationState(customerNr: string | null) {
    const [customerValidationState] = useParkingaboServerFetch<
        PublicValidationResponse,
        { customerNr: string },
        { reason: ValidationErrorReason }
    >(
        ({ customerNr }) => ({
            url: `/ui-api/parkingabo/customer/${customerNr}/validation`,
        }),
        customerNr ? { customerNr: customerNr } : null,
    );

    return [customerValidationState];
}

export function PublicParkingaboCustomerValidationRoute() {
    const customerNr = useParamOrNull('customerNr');
    const navigate = useNavigate();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );
    const [emailToBeConfirmed, setEmailToBeConfirmed] = useState<string | null>(
        null,
    );
    const [customerValidationState] =
        usePublicCustomerValidationState(customerNr);

    const { anotherLinkState, requestAnotherLink } =
        useResentVerificationMail();

    useNavigateOnSuccess(
        anotherLinkState,
        `/register-with-customer/${customerNr}/email-sent`,
    );

    const [removeCustomerRegistrationState, removeCustomerRegistration] =
        useParkingaboServerWrite<
            {
                customerNr: string;
                email: string;
            },
            never
        >(() => ({
            url: `/ui-api/parkingabo/customer/${customerNr}/remove-customer-registration`,
        }));
    useNavigateOnSuccess(
        removeCustomerRegistrationState,
        `/register-with-customer/${customerNr}`,
    );
    useNavigateOnError(removeCustomerRegistrationState, '/login');

    useServerSuccessEffect(customerValidationState, data => {
        if (data.isOnboardingComplete) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE,
            );
        } else if (data.hasLogin && !data.emailToBeConfirmed) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING,
            );
        } else if (data.hasLogin) {
            setValidationState(ValidationState.CONFIRM_EMAIL);
            setEmailToBeConfirmed(data.emailToBeConfirmed);
        } else {
            navigate(`/register-with-customer/${customerNr}`);
        }
    });

    useServerErrorEffect(customerValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case ValidationErrorReason.NOT_FOUND:
                    setValidationState(ValidationState.NOT_FOUND);
                    break;
                case ValidationErrorReason.WRONG_CUSTOMER:
                    setValidationState(ValidationState.WRONG_CUSTOMER);
                    break;
            }
        }
    });

    if (customerNr === null) {
        return <Navigate to="/login" />;
    }

    return (
        <PublicValidationContent
            validationState={validationState}
            emailToBeConfirmed={emailToBeConfirmed}
            requestAnotherLink={() =>
                requestAnotherLink({ email: emailToBeConfirmed! })
            }
            removeRegistration={() =>
                removeCustomerRegistration({
                    customerNr: customerNr!,
                    email: emailToBeConfirmed!,
                })
            }
            origin={ValidationOrigin.CUSTOMER}
        />
    );
}

export function AuthedParkingaboCustomerValidationRoute() {
    const customerNr = useParamOrNull('customerNr');
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );

    const [customerValidationState] = useParkingaboServerFetch<
        null,
        { customerNr: string },
        { reason: ValidationErrorReason }
    >(
        ({ customerNr }) => ({
            url: `/ui-api/parkingabo/user/customer/${customerNr}/validation`,
        }),
        customerNr ? { customerNr: customerNr } : null,
    );

    useServerSuccessEffect(customerValidationState, () => {
        navigate(generateAuthedParkingaboPath('/'));
    });

    useServerErrorEffect(customerValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case ValidationErrorReason.NOT_FOUND:
                    setValidationState(ValidationState.NOT_FOUND);
                    break;
                case ValidationErrorReason.WRONG_CUSTOMER:
                    setValidationState(ValidationState.WRONG_CUSTOMER);
                    break;
            }
        }
    });

    if (customerNr === null) {
        navigate(generateAuthedParkingaboPath('/'));
    }

    return (
        <AuthedValidationContent
            validationState={validationState}
            origin={ValidationOrigin.CUSTOMER}
        />
    );
}
