import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../api/ParkingaboApi.tsx';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    useNavigateOnError,
    useNavigateOnSuccess,
    useServerErrorEffect,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useState } from 'react';
import { useParkingaboAuthedPathGeneration } from '../RouteUtils.tsx';
import {
    AuthedValidationContent,
    PublicValidationContent,
    PublicValidationResponse,
    useResentVerificationMail,
    ValidationErrorReason,
    ValidationOrigin,
    ValidationState,
} from './ParkingaboSharedRegistrationValidationRoute.tsx';

export function usePublicBadgeValidationState(badgeLabelNr: string | null) {
    const [badgeValidationState] = useParkingaboServerFetch<
        PublicValidationResponse,
        { badgeLabelNr: string },
        { reason: ValidationErrorReason }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    return [badgeValidationState];
}

export function PublicParkingaboBadgeValidationRoute() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );
    const [emailToBeConfirmed, setEmailToBeConfirmed] = useState<string | null>(
        null,
    );
    const [badgeValidationState] = usePublicBadgeValidationState(badgeLabelNr);

    const { anotherLinkState, requestAnotherLink } =
        useResentVerificationMail();

    useNavigateOnSuccess(
        anotherLinkState,
        `/register-with-badge/${badgeLabelNr}/email-sent`,
    );

    const [removeBadgeRegistrationState, removeBadgeRegistration] =
        useParkingaboServerWrite<
            {
                badgeLabelNr: string;
                email: string;
            },
            never
        >(() => ({
            url: `/ui-api/parkingabo/badge/${badgeLabelNr}/remove-badge-registration`,
        }));
    useNavigateOnSuccess(
        removeBadgeRegistrationState,
        `/register-with-badge/${badgeLabelNr}`,
    );
    useNavigateOnError(removeBadgeRegistrationState, '/login');

    useServerSuccessEffect(badgeValidationState, data => {
        if (data.isOnboardingComplete) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE,
            );
        } else if (data.hasLogin && !data.emailToBeConfirmed) {
            setValidationState(
                ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING,
            );
        } else if (data.hasLogin) {
            setValidationState(ValidationState.CONFIRM_EMAIL);
            setEmailToBeConfirmed(data.emailToBeConfirmed);
        } else {
            navigate(`/register-with-badge/${badgeLabelNr}`);
        }
    });

    useServerErrorEffect(badgeValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case ValidationErrorReason.NOT_FOUND:
                    setValidationState(ValidationState.NOT_FOUND);
                    break;
                case ValidationErrorReason.WRONG_CUSTOMER:
                    setValidationState(ValidationState.WRONG_CUSTOMER);
                    break;
            }
        }
    });

    if (badgeLabelNr === null) {
        return <Navigate to="/login" replace />;
    }

    return (
        <PublicValidationContent
            validationState={validationState}
            emailToBeConfirmed={emailToBeConfirmed}
            requestAnotherLink={() =>
                requestAnotherLink({ email: emailToBeConfirmed! })
            }
            removeRegistration={() =>
                removeBadgeRegistration({
                    badgeLabelNr: badgeLabelNr!,
                    email: emailToBeConfirmed!,
                })
            }
            origin={ValidationOrigin.BADGE}
        />
    );
}

export function AuthedParkingaboBadgeValidationRoute() {
    const badgeLabelNr = useParamOrNull('badgeLabelNr');
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );

    const [badgeValidationState] = useParkingaboServerFetch<
        null,
        { badgeLabelNr: string },
        { reason: ValidationErrorReason }
    >(
        ({ badgeLabelNr }) => ({
            url: `/ui-api/parkingabo/user/badge/${badgeLabelNr}/validation`,
        }),
        badgeLabelNr ? { badgeLabelNr: badgeLabelNr } : null,
    );

    useServerSuccessEffect(badgeValidationState, () => {
        navigate(generateAuthedParkingaboPath('/'));
    });

    useServerErrorEffect(badgeValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case ValidationErrorReason.NOT_FOUND:
                    setValidationState(ValidationState.NOT_FOUND);
                    break;
                case ValidationErrorReason.WRONG_CUSTOMER:
                    setValidationState(ValidationState.WRONG_CUSTOMER);
                    break;
            }
        }
    });

    if (badgeLabelNr === null) {
        navigate(generateAuthedParkingaboPath('/'));
    }

    return (
        <AuthedValidationContent
            validationState={validationState}
            origin={ValidationOrigin.BADGE}
        />
    );
}
