import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';

export enum StatusBarColorVariants {
    ACTIVE = 'ACTIVE',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    DISABLED = 'DISABLED',
}

function getBackgroundColorForVariant(
    theme: Theme,
    colorVariant: StatusBarColorVariants,
) {
    switch (colorVariant) {
        case StatusBarColorVariants.ACTIVE:
            return theme.palette.primary.main;
        case StatusBarColorVariants.SUCCESS:
            return theme.palette.success.main;
        case StatusBarColorVariants.WARNING:
            return theme.palette.warning.main;
        case StatusBarColorVariants.DISABLED:
            return theme.palette.grey[600];
    }
}

export function StatusBar({
    children,
    rightLabel,
    colorVariant = StatusBarColorVariants.ACTIVE,
}: {
    children: ReactNode;
    rightLabel?: ReactNode;
    colorVariant?: StatusBarColorVariants;
}) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            sx={{
                width: '100%',
                paddingX: 3,
                paddingY: 0.5,
                backgroundColor: theme =>
                    getBackgroundColorForVariant(theme, colorVariant),
                textTransform: 'uppercase',
            }}
        >
            <Typography
                variant="subtitle2"
                sx={theme => ({ color: theme.palette.primary.contrastText })}
            >
                {children}
            </Typography>
            {rightLabel && (
                <Typography
                    variant="subtitle2"
                    sx={theme => ({
                        color: theme.palette.primary.contrastText,
                    })}
                >
                    {rightLabel}
                </Typography>
            )}
        </Stack>
    );
}
