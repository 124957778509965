import { Box, Stack } from '@mui/material';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboMenuListItem } from '../ParkingaboMenuListItem';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import {
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { BlockingLoadingModal } from '../BlockingLoadingModal';
import { generatePath } from 'react-router';
import { PaymentIcon } from '../PaymentIcon';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers';
import { Language } from 'dg-web-shared/lib/Localized';
import { CurrencyExchange } from '@mui/icons-material';

export enum ParkingaboPaymentMethods {
    TWINT = 'TWINT',
    CARDS = 'CARDS',
    POSTFINANCE = 'POSTFINANCE',
}

export enum PaymentMethodType {
    ONBOARDING = 'ONBOARDING',
    REGISTER = 'REGISTER',
}

interface AliasCreationRequestPayload {
    paymentMethod: ParkingaboPaymentMethods;
    baseUri: string;
    language: Language;
}

export function PaymentMethodForm({
    paymentMethodType,
}: {
    paymentMethodType?: PaymentMethodType;
}) {
    const tenantId = useParamOrNull('tenantId');
    const { language } = useLanguage();
    const [externalUriState, fetchExternalUri] = useParkingaboServerWrite<
        AliasCreationRequestPayload,
        { redirectUri: string }
    >(() => ({
        url: `/ui-api/parkingabo/user/self/payment/start-alias-registration`,
    }));

    function handlePaymentMethodFlow(
        e: MouseEvent | undefined,
        paymentMethod: ParkingaboPaymentMethods,
        language: Language,
    ) {
        if (e) {
            e.preventDefault();
        }

        const baseUri = generatePath(
            getPaymentBaseUriByPaymentMethodType(paymentMethodType),
            { tenantId },
        );
        fetchExternalUri({
            paymentMethod,
            baseUri: `${window.location.protocol}//${window.location.host}${baseUri}`,
            language: language,
        });
    }

    useServerSuccessEffect(externalUriState, data => {
        location.href = data.redirectUri;
    });

    return (
        <Box>
            <PeriodicityOfChargeInfo />
            <ParkingaboMenuListItem
                onClick={e =>
                    handlePaymentMethodFlow(
                        e,
                        ParkingaboPaymentMethods.TWINT,
                        language,
                    )
                }
                to=""
                icons={[<PaymentIcon icon="twi" key="twi" />]}
                text={<Localized de="TWINT" fr="TWINT" it="TWINT" en="TWINT" />}
            />
            <ParkingaboMenuListItem
                onClick={e =>
                    handlePaymentMethodFlow(
                        e,
                        ParkingaboPaymentMethods.CARDS,
                        language,
                    )
                }
                to=""
                icons={[
                    <PaymentIcon icon="vis" key="vis" />,
                    <PaymentIcon icon="eca" key="eca" />,
                    <PaymentIcon icon="amx" key="amx" />,
                ]}
                text={
                    <Localized de="Karten" fr="Cartes" it="Carte" en="Cards" />
                }
            />
            <ParkingaboMenuListItem
                onClick={e =>
                    handlePaymentMethodFlow(
                        e,
                        ParkingaboPaymentMethods.POSTFINANCE,
                        language,
                    )
                }
                to=""
                text={
                    <Localized
                        de="Postfinance"
                        fr="Postfinance"
                        it="Postfinance"
                        en="Postfinance"
                    />
                }
            />
            <BlockingLoadingModal
                open={
                    externalUriState.status === RequestStatus.PENDING ||
                    externalUriState.status === RequestStatus.SUCCESS
                }
            />
        </Box>
    );
}

export function getPaymentBaseUriByPaymentMethodType(
    paymentMethodType?: PaymentMethodType,
) {
    switch (paymentMethodType) {
        case PaymentMethodType.ONBOARDING:
            return '/:tenantId/onboarding-payments';
        case PaymentMethodType.REGISTER:
            return '/:tenantId/register-payments';
    }
    return '';
}

function PeriodicityOfChargeInfo() {
    return (
        <PaymentCollectionInfoBanner>
            <Localized
                de={
                    <>
                        Die Parkgebühren werden <b>einmal monatlich </b>
                        auf dem Zahlungsmittel belastet.
                    </>
                }
                fr={
                    <>
                        Les frais de stationnement seront débités
                        <b> une fois par mois </b>sur le moyen de paiement.
                    </>
                }
                it={
                    <>
                        Le tasse di parcheggio verranno addebitate
                        <b> una volta al mese </b>sul mezzo di pagamento.
                    </>
                }
                en={
                    <>
                        The parking fees will be debited to the payment mean
                        <b> once a month</b>.
                    </>
                }
            />
        </PaymentCollectionInfoBanner>
    );
}

export function PaymentCollectionInfoBanner({
    children,
}: {
    children: JSX.Element;
}) {
    return (
        <Stack
            direction="row"
            spacing={2.5}
            sx={{
                width: '100%',
                paddingX: 2.5,
                paddingY: 2.5,
                backgroundColor: theme => theme.palette.grey[300],
                display: 'flex',
                alignItems: 'center',
                fontSize: 14,
                fontWeight: 400,
            }}
        >
            <CurrencyExchange sx={{ width: '36px', height: '36px' }} />
            <Box>{children}</Box>
        </Stack>
    );
}
